const theme = {
    token: {
        colorPrimary: '#1F32D6',
        fontFamily:
            'Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Oxygen", "Helvetica Neue", sans-serif',
        colorLink: '#1F32D6',
        colorInfo: '#1F32D6',
        colorError: '#E74E5A',
        colorErrorHover: '#E74E5A',
        colorSuccessBgHover: '#26C165',
        colorWarning: '#FA8C16',
        colorWarningHover: '#FA8C16',
        colorBorder: '#EDEEF3',
        colorText: '#1C1C1C',
        colorTextDescription: '#73798B',
        colorTextQuaternary: '#73798B',
        colorTextPlaceholder: '#B9BCC5',
        borderRadiusLG: 16,
        borderRadiusSM: 6,
        borderRadiusXS: 4,
        colorBgMask: 'rgba(0, 0, 0, 0.75)',
        colorBgSpotlight: '#0A0A0A',
        colorLinkHover: '#1F32D6',
        colorLinkActive: '#131E80',
        boxShadow: '0px 1px 0px 0px #EDEEF3 inset;',
        fontSize: 14,
        fontFamily: 'Poppins',
        controlItemBgActive: '#F8F9FC',
        controlItemBgHover: '#F8F9FC',
        controlOutlineWidth: 4,
        controlOutline: 'rgba(31, 50, 214, 0.15)',
    },
    components: {
        QRCode: {
            colorSplit: '#EDEEF3',
            paddingSM: 10,
        },
        Select: {
            colorBgContainerDisabled: '#F8F9FC',
            borderRadiusLG: 6,
            borderRadiusSM: 4,
            multipleItemBg: '#EDEEF3',
            fontFamily: 'Poppins',
            controlHeightLG: 46,
            controlItemBgActive: 'transparent',
            activeBorderColor: 'rgba(31, 50, 214, 0.5)',
            hoverBorderColor: 'rgba(31, 50, 214, 0.5)',
        },
        Input: {
            paddingInlineLG: 16,
            paddingBlockLG: 10,
            fontFamily: 'Poppins',
            activeBorderColor: 'rgba(31, 50, 214, 0.5)',
            hoverBorderColor: 'rgba(31, 50, 214, 0.5)',
        },
        InputNumber: {
            borderRadiusLG: 6,
            controlHeight: 32,
            controlHeightLG: 44,
        },
        Radio: {
            dotSize: 7,
            colorTextDisabled: '#B9BCC5',
            motionDurationFast: '0',
            motionDurationMid: '0',
            motionDurationSlow: '0',
        },
        Dropdown: {
            borderRadiusLG: 6,
            borderRadiusSM: 4,
        },
        Popover: {
            borderRadiusLG: 6,
        },
        Notification: {
            borderRadiusLG: 12,
            boxShadow: '0px 0px 18px rgba(10, 31, 68, 0.12)',
        },
        Checkbox: {
            borderRadiusSM: 3,
            colorTextDisabled: '#B9BCC5',
            motionDurationFast: '0',
            motionDurationMid: '0',
            motionDurationSlow: '0',
        },
        Avatar: {
            borderRadiusLG: 6,
            borderRadius: 6,
            borderRadiusSM: 6,
        },
        DatePicker: {
            activeBorderColor: 'rgba(31, 50, 214, 0.5)',
            hoverBorderColor: 'rgba(31, 50, 214, 0.5)',
        },
        Button: {
            defaultShadow: 'none',
            primaryShadow: 'none',
            dangerShadow: 'none',
        },
    },
};

export default theme;
